<template>
  <CContainer class="min-vh-100 d-flex align-items-center">
    <CRow class="w-100 justify-content-center">
      <CCol md="6" sm="8">
        <CCard class="mx-4 mb-0">
          <CCardBody class="p-4">
            <CForm>
              <h1>{{$t('REGISTER_PAGE_TITLE')}}</h1>
              <p class="text-muted">{{$t('REGISTER_PAGE_CREATE_ACCOUNT')}}</p>
              <CInput
                :placeholder="$t('REGISTER_PAGE_LABEL_USERNAME')"
                autocomplete="username"
              >
                <template #prepend-content><CIcon name="cil-user"/></template>
              </CInput>
              <CInput
                :placeholder="$t('REGISTER_PAGE_LABEL_EMAIL')"
                autocomplete="email"
                prepend="@"
              />
              <CInput
                :placeholder="$t('REGISTER_PAGE_LABEL_PASSWORD')"
                type="password"
                autocomplete="new-password"
              >
                <template #prepend-content><CIcon name="cil-lock-locked"/></template>
              </CInput>
              <CInput
                :placeholder="$t('REGISTER_PAGE_LABEL_REPEAT_PASSWORD')"
                type="password"
                autocomplete="new-password"
                class="mb-4"
              >
                <template #prepend-content><CIcon name="cil-lock-locked"/></template>
              </CInput>
              <CButton color="success" block>{{$t('REGISTER_PAGE_BUTTON_CREATE_ACCOUNT')}}</CButton>
            </CForm>
          </CCardBody>
          <CCardFooter class="p-4">
            <CRow>
              <CCol col="6">
                <CButton block color="facebook">
                  {{$t('REGISTER_PAGE_BUTTON_FACEBOOK')}}
                </CButton>
              </CCol>
              <CCol col="6">
                <CButton block color="twitter">
                   {{$t('REGISTER_PAGE_BUTTON_TWITTER')}}
                </CButton>
              </CCol>
            </CRow>
          </CCardFooter>
        </CCard>
      </CCol>
    </CRow>
  </CContainer>
</template>

<script>
export default {
  name: 'Register'
}
</script>
